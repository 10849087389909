import { isExperimentEnabled } from '@wix/communities-blog-client-common';
import { EXPERIMENT_CATEGORY_MENU_MOBILE_SETTINGS } from '@wix/communities-blog-experiments';
import { getAppSettingsValue } from '../../common/selectors/app-settings-base-selectors';
import { getIsMobileDisplaySettingsEnabled } from '../../common/selectors/mobile-settings-selectors';
import { getIsMobile } from '../../common/store/basic-params/basic-params-selectors';

export const getShowAllPostsCategory = (state, categoryMenuParams) =>
  getAppSettingsValue({
    state,
    key: categoryMenuParams.showAllPostsCategory.appSettingsPath,
    fallback: categoryMenuParams.showAllPostsCategory.defaultValue,
  });

export const getShowPostCount = (state, categoryMenuParams) =>
  getAppSettingsValue({
    state,
    key: categoryMenuParams.showPostCount.appSettingsPath,
    fallback: categoryMenuParams.showPostCount.defaultValue,
  });

export const getUseMobileSettings = (state) =>
  isExperimentEnabled(state, EXPERIMENT_CATEGORY_MENU_MOBILE_SETTINGS) &&
  getIsMobile(state) &&
  getIsMobileDisplaySettingsEnabled(state);
